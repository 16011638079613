import api from '@/services/api';

const url = 'compliance-subcategory';

const getSubcategory = () => api.get(`${url}/read-compliance-subcategory`);

const createSubcategory = (subcategory) => api.post(`${url}/create-compliance-subcategory`, subcategory);

const updateSubcategory = (subcategory) => api.put(`${url}/update-compliance-subcategory`, subcategory);

const deleteSubcategory = (id) => api.delete(`${url}/delete-compliance-subcategory/${id}`);

export default {
  getSubcategory,
  createSubcategory,
  updateSubcategory,
  deleteSubcategory,
};
