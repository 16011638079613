<template>
  <div class="pa-6 pt-10">
    <v-data-table :headers="headers" :items="subcategory" :search="search">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Subcategorias de Compliance</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar "
            single-line
            hide-details />

          <v-spacer />
          <v-btn color="primary" dark class="mb-2" @click="dialog = true">
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="prepareToDelete(item)"
              v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #[`item.active`]="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          value
          disabled />
      </template>
      <template #[`item.createdAt`]="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" hide-overlay fullscreen>
      <form-subcategory
        v-if="dialog"
        :populate-with="subcategoryToEdit"
        @subcategory-added="addSubcategory"
        @subcategory-edited="editSubcategory"
        @close-dialog="closeDialog" />
    </v-dialog>
    <delete-confirmation
      :visible="dialogDeleteConfirmation"
      @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem()" />
  </div>
</template>

<script>
import ComplianceSubcategoryService from '../../../services/compliance-subcategory.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'ComplianceCategory',
  components: {
    'form-subcategory': () => import('./Form'),
    DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Titulo da Subcategoria',
        sortable: false,
        value: 'name',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    subcategory: [],
    search: '',
    dialog: false,
    subcategoryToEdit: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: null,
  }),

  async created() {
    await this.loadSubcategories();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.subcategoryToEdit = {};
    },
    async loadSubcategories() {
      const data = await ComplianceSubcategoryService.getSubcategory();
      this.subcategory = data;
    },
    async addSubcategory(subcategory) {
      this.subcategory.push(subcategory);
      await this.loadSubcategories();
      this.dialog = false;
    },

    async editSubcategory(subcategory) {
      const index = this.subcategory.findIndex((listSubcategory) => listSubcategory.id === subcategory.id);
      this.subcategory[index] = subcategory;
      await this.loadSubcategories();
    },

    editItem(subcategory) {
      this.subcategoryToEdit = subcategory;
      this.dialog = true;
    },

    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },

    async deleteItem() {
      try {
        this.dialogDeleteConfirmation = false;
        await ComplianceSubcategoryService.deleteSubcategory(this.deleteItemID);
        this.$toast.success('Subcategoria excluída com sucesso');
        const index = this.subcategory.findIndex((listSubcategory) => listSubcategory.id === this.deleteItemID);
        this.subcategory.splice(index, 1);
      } catch (e) {
        this.$handleHttpError(e);
      }
    },
  },
};
</script>
